import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { IProductIngredientsProps } from './model';
import './ProductIngredients.scss';

const ProductIngredients: FC<IProductIngredientsProps> = ({
  title,
  image,
  imageAlt,
  mobileImage,
  mobileImageAlt,
  ingredientsInfo,
  ingredientDisclaimer,
  ctaButton,
  ariaCtaLabel,
  ctaButtonNotice,
  howItWorksButton,
  ariaHowItWorksLabel,
}) => {
  const { isNotMobile, isMobile } = useScreenRecognition();
  const ctaPath = (ctaButton?.[0]?.url || '').concat(ctaButton?.[0]?.queryString || '');
  const howItWorksPath = (howItWorksButton?.[0]?.url || '').concat(
    howItWorksButton?.[0]?.queryString || ''
  );

  return (
    <div className="product-ingredients" id="ingredients" data-testid="product-ingredients">
      <Container fluid>
        <DangerouslySetInnerHtml className="product-ingredients__title" element="h2" html={title} />
      </Container>

      <div className="product-ingredients__image">
        {isNotMobile ? <GatsbyImage useFocalPoint fluid={image} alt={imageAlt} /> : null}

        {isMobile ? <GatsbyImage useFocalPoint fluid={mobileImage} alt={mobileImageAlt} /> : null}
      </div>

      <Container fluid>
        <div className="product-ingredients__info">
          {ingredientsInfo?.length
            ? ingredientsInfo.map(({ properties: { title: ingredientTitle, description } }) => (
                <div key={title}>
                  <h2 className="product-ingredients__info-title">{ingredientTitle}</h2>
                  {description ? (
                    <DangerouslySetInnerHtml
                      className="product-ingredients__info-description"
                      html={description}
                    />
                  ) : null}
                </div>
              ))
            : null}
        </div>

        {ingredientDisclaimer ? (
          <DangerouslySetInnerHtml
            className="product-ingredients__disclaimer"
            html={ingredientDisclaimer}
          />
        ) : null}

        {ctaButton?.length ? (
          <div className="product-ingredients__actions">
            <div className="product-ingredients__actions-buy">
              <Button
                to={ctaPath}
                target={ctaButton[0]?.target}
                classes="product-ingredients__actions-buy-btn"
                ariaLabel={ariaCtaLabel}
                variant="buy"
              >
                {ctaButton[0].name}
                <IconCustom icon="chevron-right" />
              </Button>

              {ctaButtonNotice ? (
                <DangerouslySetInnerHtml
                  className="product-ingredients__actions-notice"
                  html={ctaButtonNotice}
                />
              ) : null}
            </div>

            {howItWorksButton?.length ? (
              <div className="product-ingredients__actions-how">
                <Button
                  to={howItWorksPath}
                  target={howItWorksButton[0]?.target}
                  classes="product-ingredients__actions-how-btn"
                  ariaLabel={ariaHowItWorksLabel}
                  variant="info"
                >
                  {howItWorksButton[0].name}
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentProductIngredients on TIngredientSectionStructure {
    structure
    properties {
      title
      image {
        ...FragmentGatsbyImage
      }
      imageAlt
      mobileImage {
        ...FragmentGatsbyImage
      }
      mobileImageAlt
      ingredientsInfo {
        properties {
          title
          description
        }
      }
      ingredientDisclaimer
      ctaButton {
        name
        url
        target
        queryString
      }
      ariaCtaLabel
      ctaButtonNotice
      howItWorksButton {
        name
        url
        target
        queryString
      }
      ariaHowItWorksLabel
    }
  }
`;

export default ProductIngredients;

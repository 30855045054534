import React, { FC, useMemo } from 'react';

import IconCustom from 'components/common/IconCustom';
import './ProductFormats.scss';
import { Link } from 'gatsby';

const ProductFormats: FC<ProductPageTypes.IProductFormats> = ({
  otherFormats,
  currentFormat,
  title,
}) => {
  const formats = useMemo(
    () =>
      [...otherFormats, ...currentFormat].sort((a, b) =>
        a.properties.type[0] > b.properties.type[0] ? 1 : -1
      ),
    [otherFormats, currentFormat]
  );

  return (
    <div className="product-formats" data-testid="product-formats">
      <strong className="product-formats__title">{title}</strong>
      <ul className="product-formats__list">
        {formats.map(({ properties: { type: [icon], label, link } }) => (
          <li key={icon} className="product-formats__list-item">
            {link?.length ? (
              <Link
                className="product-formats__link product-formats__link--inactive"
                to={link[0].url}
              >
                <div className="product-formats__circle">
                  <IconCustom icon={`${icon}-format`} />
                </div>
                <span className="product-formats__label">{label}</span>
              </Link>
            ) : (
              <div className="product-formats__link product-formats__link--active">
                <div className="product-formats__circle">
                  <IconCustom icon={`${icon}-format`} />
                </div>
                <span className="product-formats__label">{label}</span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductFormats;

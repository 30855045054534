import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import SymptomBlock from 'components/SymptomBlock';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { ProductType, ProductTypeItem } from './model';
import './ProductDetailsGavinatura.scss';

const ProductDetailsGavinatura: FC<ProductPageTypes.IProductDetailsGavinatura> = ({
  productDetailsData,
}) => {
  const {
    descriptionShort,
    productImage,
    productImageAlt,
    symptoms,
    commonProductSettings: { availableFlavorsLabel, availableSizesLabel, chooseFormatLabel },
    flavours = [],
    sizes = [],
    currentFormat = [],
  } = productDetailsData;

  const { isNotMobile, isMobile } = useScreenRecognition();
  const titles = [chooseFormatLabel, availableFlavorsLabel, availableSizesLabel];
  const productTypes: ProductTypeItem[] = currentFormat
    .concat(flavours, sizes)
    .map(({ properties }: ProductType, idx) => ({
      title: titles[idx],
      label: properties?.label,
      icon: properties?.icon || properties?.type,
    }));

  return (
    <div className="product-details" data-testid="product-details">
      <Container fluid>
        <div className="product-details__grid">
          {productImage ? (
            <div className="product-details__image">
              {isNotMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: 'initial' }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                />
              ) : null}
              {isMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: 'initial' }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                  fadeIn={false}
                />
              ) : null}
            </div>
          ) : null}

          <div className="product-details__content">
            {productTypes.length ? (
              <div className="product-types">
                <ul className="product-types__list">
                  {productTypes.map(({ title, label, icon }) => (
                    <li key={label} className="product-types__item">
                      <span className="product-types__item-title">{title}</span>
                      <IconCustom icon={icon?.[0]} />
                      <span className="product-types__item-label">{label}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            <div className="product-details__text">
              {symptoms?.length
                ? symptoms.map(({ properties: { text, color } }) => (
                    <SymptomBlock key={text} text={text} color={color} />
                  ))
                : null}
              {descriptionShort ? (
                <DangerouslySetInnerHtml
                  className="product-details__short-description"
                  html={descriptionShort}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductDetailsGavinatura;

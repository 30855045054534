const responsiveObj = {
  superLargeDesktop: {
    breakpoint: { max: 6000, min: 3000 },
    items: 3,
    partialVisibilityGutter: -1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: -1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    partialVisibilityGutter: -1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    partialVisibilityGutter: -1,
  },
};

const responsiveObjArticle = {
  superLargeDesktop: {
    breakpoint: { max: 6000, min: 3000 },
    items: 2,
    partialVisibilityGutter: -1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: -1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    partialVisibilityGutter: -1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    partialVisibilityGutter: -1,
  },
};

export { responsiveObj, responsiveObjArticle };

import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';

import './TestimonialSection.scss';

const TestimonialSection: FC<ProductPageTypes.ITestimonialSection> = ({
  bgColor,
  image,
  imageAlt,
  imageAlignment,
  description,
  imageAvatar,
  imageAvatarAlt,
}) => {
  const color = bgColor?.length ? bgColor[0] : null;
  const align = imageAlignment?.length ? imageAlignment[0] : null;
  const sectionCSSClassNames = classnames('testimonial-section', {
    [`testimonial-section--bg-${color}`]: color,
    [`testimonial-section--image-${align}`]: align,
  });

  return (
    <div className={sectionCSSClassNames} data-testid="testimonial-section">
      <Container fluid>
        <div className="testimonial-section__grid">
          {image?.fallbackUrl ? (
            <div className="testimonial-section__image">
              <GatsbyImage
                className="testimonial-section__image-img"
                fluid={image}
                alt={imageAlt}
              />
            </div>
          ) : null}

          <div className="testimonial-section__text">
            {imageAvatar?.fallbackUrl ? (
              <GatsbyImage
                className="testimonial-section__avatar-img"
                fluid={imageAvatar}
                alt={imageAvatarAlt}
              />
            ) : null}
            <div className="testimonial-section__description">
              <IconCustom icon="quote" />
              <DangerouslySetInnerHtml
                className="testimonial-section__blockquote"
                html={description}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TestimonialSection;

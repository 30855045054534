import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';

import useScreenRecognition from 'hooks/useScreenRecognition';
import './FeaturedArticle.scss';
import { parseBoolean } from 'utils/parseHelpers';

const FeaturedArticle: FC<ProductPageTypes.IFeaturedArticle> = ({
  link,
  featuredArticles,
  ariaButton,
  color,
  imageAlign,
  isManual: isManualValue = '0',
  manualTitle,
  manualDescription,
  manualImage,
  manualImageAlt,
}) => {
  const bgColor = color?.length ? color[0] : null;
  const align = imageAlign?.length ? imageAlign[0] : null;
  const cx = classnames({
    'featured-article': true,
    [`featured-article--bg-${bgColor}`]: bgColor,
    [`featured-article--image-${align}`]: align,
  });
  const article = featuredArticles?.nodes.find((node) => node.link === link[0].url);
  const category = article?.tags.find((tag) => tag.articleCategory === '1');
  const { isMobile } = useScreenRecognition();
  const isManual = parseBoolean(isManualValue);
  const title = isManual ? manualTitle : article?.title;
  const description = isManual ? manualDescription : article?.text;
  const image = isManual ? manualImage : article?.image;
  const imageAlt = isManual ? manualImageAlt : article?.alt;

  const isShow = article || isManual;

  return isShow ? (
    <div className={cx} data-testid="featured-article">
      <Container fluid>
        <div className="featured-article__wrap">
          {image?.fallbackUrl ? (
            <GatsbyImage
              className="featured-article__image"
              fluid={image}
              alt={imageAlt}
              isLazyLoading={false}
              {...(isMobile && { fadeIn: false })}
            />
          ) : null}
          <div className="featured-article__text">
            {category?.link?.[0].name ? (
              <strong className="featured-article__sub-title">{category.link[0].name}</strong>
            ) : null}
            {title ? (
              <DangerouslySetInnerHtml
                className="featured-article__title"
                element="h2"
                html={title}
              />
            ) : null}
            {description ? (
              <DangerouslySetInnerHtml
                className="featured-article__description"
                html={description}
              />
            ) : null}
            <Button
              ariaLabel={ariaButton}
              to={link[0].url}
              variant={color?.[0] === 'green' ? 'primary' : 'secondary'}
            >
              {link[0].name}
              <IconCustom icon="chevron-right" />
            </Button>
          </div>
        </div>
      </Container>
    </div>
  ) : null;
};

export default FeaturedArticle;

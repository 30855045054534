import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import useScreenRecognition from 'hooks/useScreenRecognition';
import './ProductAdditionalInfo.scss';

const ProductAdditionalInfo: FC<ProductPageTypes.TAdditionalInfoProps> = ({
  mainImage,
  mainImageAlt,
  cards,
  infoBlock,
}) => {
  const { isNotMobile, isMobile } = useScreenRecognition();
  return (
    <div data-testid="product-additional-info">
      <Container fluid>
        {mainImage ? (
          <div className="product-additional-info__mainImage">
            {isNotMobile ? (
              <GatsbyImage
                isLazyLoading={false}
                objectFit="contain"
                objectPosition="50% 100%"
                fluid={mainImage}
                alt={mainImageAlt}
              />
            ) : null}
            {isMobile ? (
              <GatsbyImage
                isLazyLoading={false}
                objectFit="contain"
                objectPosition="50% 100%"
                fluid={mainImage}
                alt={mainImageAlt}
                fadeIn={false}
              />
            ) : null}
          </div>
        ) : null}
        {infoBlock ? (
          <Row className="product-additional-info__section">
            <Col md="3" className="product-additional-info__infoImage">
              <GatsbyImage
                isLazyLoading={false}
                objectFit="contain"
                fluid={infoBlock[0].properties.image}
                alt={infoBlock[0].properties.imageAlt}
              />
            </Col>
            <Col md="9">
              <DangerouslySetInnerHtml element="div" html={infoBlock[0].properties.text} />
            </Col>
          </Row>
        ) : null}
        {cards ? (
          <Row className="product-additional-info__section">
            {cards.map(({ properties: { image, imageAlt, text } }, index) => (
              <Col md="3" key={index}>
                <GatsbyImage
                  isLazyLoading={false}
                  className="product-additional-info__card-image"
                  objectFit="initial"
                  useFocalPoint
                  fluid={image}
                  alt={imageAlt}
                />
                <div className="product-additional-info__card-text">{text}</div>
              </Col>
            ))}
          </Row>
        ) : null}
      </Container>
    </div>
  );
};

export default ProductAdditionalInfo;

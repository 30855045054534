import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';
import GatsbyImage from 'components/common/GatsbyImage';
import { parseBoolean } from 'utils/parseHelpers';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import './ProductBanner.scss';
import './ProductBannerOverride.scss';

const ProductBanner: FC<ProductPageTypes.IProductBanner> = ({
  title,
  subTitle,
  image,
  mobileImage,
  mobileImageAlt,
  imageAlt,
  formatLabel,
  logo,
  logoAlt,
  tags,
  hideLogo,
  hideVariant,
  bannerBackground,
  additionalBannerImage,
  additionalBannerImageAlt,
  bannerTheme,
}) => {
  const colorTag = tags.find((tag) => parseBoolean(tag.isProductFamily));
  const cx = classnames('product-banner', {
    [`product-banner--${colorTag?.color?.label}`]: colorTag?.color?.label,
    [`product-banner--background-${bannerBackground?.label}`]: bannerBackground?.label,
    [`product-banner--${bannerTheme}`]: bannerTheme,
  });

  const textBlockClasses = classnames('product-banner__text', {
    'product-banner__text-with-image': additionalBannerImage?.fallbackUrl,
  });

  const { isMobile, isNotMobile } = useScreenRecognition();
  const isLogoShown = !parseBoolean(hideLogo) && logo?.fallbackUrl;
  const isVariantShown = !parseBoolean(hideVariant) && formatLabel;

  return (
    <div className={cx} data-testid="product-banner">
      <Container fluid>
        <div className={textBlockClasses}>
          {isLogoShown ? (
            <GatsbyImage
              isLazyLoading
              className="product-banner__logo"
              fluid={logo}
              alt={logoAlt}
            />
          ) : null}
          <DangerouslySetInnerHtml
            element="h1"
            className="product-banner__text-title"
            html={title}
          />
          {subTitle ? <div className="product-banner__text-sub-title">{subTitle}</div> : null}
          {isVariantShown ? (
            <strong className="product-banner__text-subtitle">{formatLabel}</strong>
          ) : null}
        </div>
        {isMobile && image?.fallbackUrl ? (
          <div className="product-banner__image-desktop">
            <GatsbyImage
              isLazyLoading={false}
              objectFit="contain"
              className="product-banner__image-img"
              fluid={mobileImage}
              alt={mobileImageAlt}
              style={{ position: 'absolute' }}
              fadeIn={false}
            />
          </div>
        ) : null}
        {isNotMobile && image?.fallbackUrl ? (
          <div className="product-banner__image-desktop">
            <GatsbyImage
              isLazyLoading={false}
              objectFit="contain"
              className="product-banner__image-img"
              fluid={image}
              alt={imageAlt}
              style={{ position: 'absolute' }}
            />
          </div>
        ) : null}
        {isNotMobile && additionalBannerImage?.fallbackUrl ? (
          <div className="product-banner__additional-image">
            <GatsbyImage
              objectFit="contain"
              objectPosition="0 50%"
              fluid={additionalBannerImage}
              alt={additionalBannerImageAlt}
            />
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default ProductBanner;
